<template>
  <div>
    <div class='sticky top-0 bg-white z-10'>
      <h1 class='text-xl font-semibold uppercase'>
        {{title}}
      </h1>
      <h2 class='text-gray-700'>{{subtitle}}</h2>
      <h3 class='text-sm uppercase mt-1 text-gray-600'>
        <contact-share-status :shareable='drilldownContact.shareable' vertical-offset='4' />
      </h3>
      <div class='tab-container border-b pl-8 flex flex-row justify-start mt-16 mb-8 text-sm text-gray-600' style='margin-left: -4rem; margin-right: -4rem;'>
        <div class='tab' :class='selectedTab("contactDetails")'  @click='setActiveTab("contactDetails")'>Details</div>
      </div>
    </div>
    <contact-details v-if='showDetails' :contact='drilldownContact' />
    <contact-connections v-if='showConnections' />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ContactShareStatus  from '@/components/contacts/ContactShareStatus.vue'
import ContactDetails      from '@/views/contacts/ContactDetails.vue'
export default {
  name: 'ContactDrilldown',
  components: {
    ContactDetails,
    ContactShareStatus
  },
  data () {
    return {
      activeTab: 'contactDetails',
    }
  },
  computed: {
    ...mapState('contacts', [
      'drilldownContact',
    ]),

    subtitle () {
      let titleString = (this.drilldownContact.title) ? `${this.drilldownContact.title}` : ''
      let deparmentString = (this.drilldownContact.department) ? ` (${this.drilldownContact.department}) ` : ''
      let companyString = (this.drilldownContact.entity_name) ? ` @ ${this.drilldownContact.entity_name}` : ''
      return titleString + deparmentString + companyString
    },
    showConnections () {
      return this.activeTab === 'connections'
    },
    showDetails () {
      return this.activeTab === 'contactDetails'
    },
    title () {
      return this.drilldownContact.contact_name
    }
  },
  methods: {
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  }
}
</script>

<style lang='scss' scoped>
  .tab {
    @apply uppercase tracking-wide px-8 pb-2 pt-4 cursor-pointer;
  }
  .tab:hover {
    @apply bg-gray-100;
  }
  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
